@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  color: #39496d;
  font-family: "Roboto", sans-serif;
}

p {
  font-size: 14px;
  line-height: 1.2;
}

/*
a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  text-decoration: none;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #fff;
}

@media (max-width: 800px) {
  p {
    font-size: 14px;
  }

  a {
    font-size: 14px;
  }

  h1 {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  p {
    font-size: 12px;
  }

  a {
    font-size: 12px;
  }

  h1 {
    font-size: 14px;
  }
} */

/*Blue: #39496d*/
